import React from 'react';
import WinnerHero from '../Components/WinnerHero';
import LatestWinner from '../Components/LatestWinner';
import Winners from '../Components/Winners';
import { useStateValue } from '../Providers/stateProvider';
import { poolwinners } from '../utils/poolWinners';


const Home = () => {
    const [{ winners }] = Object.values(useStateValue());
    const allWinners = poolwinners(winners);

    return (
        <div className="page-wrapper">
            <WinnerHero />
            <LatestWinner winner={allWinners.length > 0 ? allWinners[0]: null } />
            <Winners winners={allWinners} />
        </div>
    );
};

export default Home;
