import React from 'react';
import { nFormatter } from '../utils';


const Counter = ({ stats}) => {
  return (
    <div className="counter-section">
      <div className="container">
        <div className="row counter-wrapper mx-0 justify-content-center">
          <div className="col-lg-4 col-sm-6 mb-30">
            <div className="counter-item">
              <div className="counter-item__icon">
                <img src="assets/images/icon/counter/1.png" alt="one" />
              </div>
              <div className="counter-item__content">
                <span>{stats.totalGameWinners}</span>
                <p>Total Winners</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-30">
            <div className="counter-item">
              <div className="counter-item__icon">
                <img src="assets/images/icon/counter/2.png" alt="two" />
              </div>
              <div className="counter-item__content">
                <span>{stats.ticketsSold}</span>
                <p>Tickets Sold</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="counter-item">
              <div className="counter-item__icon">
                <img src="assets/images/icon/counter/3.png" alt="three" />
              </div>
              <div className="counter-item__content">
                <span>{nFormatter(stats.payouts)}</span>
                <p>Payouts to Winners</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
