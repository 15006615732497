import './App.css';
import Routes from './Routes';
import Navbar from './Components/Shared/Navbar';
import Footer from './Components/Shared/Footer';

function App() {
  return (
    <>
      <Navbar />
      <Routes />
      <Footer />
    </>
  );
}

export default App;
