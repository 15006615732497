import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from '../Pages/Home';
import AllContests from '../Pages/AllContests';
import Winners from '../Pages/Winners';
import ContestDetails from '../Pages/ContestDetails';


const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={ Home } />
            <Route exact path="/contest" component={ AllContests } />
            <Route exact path="/winner" component={ Winners } />
            <Route exact path="/contest-details/:id" component={ ContestDetails } />
        </Switch>
    );
};

export default Routes;
