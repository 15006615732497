import React from 'react'

const WinnerHero = () => {
    return (
      <section className="inner-hero-section style--four">
      <div className="bg-shape"><img src="/assets/images/elements/inner-hero-shape-2.png" alt="hero"/></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="inner-page-content">
              <h2 className="title">Never miss a draw!</h2>
              <p>Easy way to buy tickets and win BIG</p>
              <p>many others anytime, anywhere</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}

export default WinnerHero;
