import React from 'react';
import ContestDetails from '../Components/ContestDetails';

const AllContests = () => {
    return (
    <ContestDetails />
    )
}

export default AllContests;
