import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero__shape"><img src="assets/images/elements/hero-shape.jpg.png" alt="shape" /></div>
      <div className="hero__element"><img src="assets/images/elements/hero-building.png" alt="building" /></div>
      <div className="hero__car wow bounceIn" data-wow-duration="0.5s" data-wow-delay="1s">
        {/* <img src="assets/images/elements/car-shadow.png" alt="shadow" className="car-shadow" /> */ }
        <img src="assets/images/elements/car-ray.png" alt="ray" className="car-ray" />
        <img src="assets/images/elements/car-light.png" alt="light" className="car-light" />
        <img src="assets/images/contest/dropping-coins.png" alt="car" className="hero-car" />
        <img src="assets/images/elements/car-star.png" alt="star" className="car-star" />
      </div>
      <div className="container">
        <div className="row justify-content-center justify-content-lg-start">
          <div className="col-lg-6 col-md-8">
            <div className="hero__content">
              <div className="hero__subtitle wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">ARE YOU FEELING LUCKY?</div>
              <h2 className="hero__title wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">big win</h2>
              <p className="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.7s">There is only one way to find out. Get into the Mitambo Arena and set your Bantu spirits in play, You might just be carting away mouthwatering rewards in Bantu Network Tokens (XBN)!</p>
              <div className="hero__btn wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.9s">
                <Link to='/contest' className="cmn-btn">Dive in</Link>
                {/* <a className="video-btn" href="https://www.youtube.com/embed/d6xn5uflUjg" data-rel="lightcase:myCollection"><i className="fas fa-play"></i></a> */ }
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero__thumb">
              <img src="assets/images/contest/gold-coins.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
