export const getWinnersFromBatch = (stream, winners) => {
    const foundRoom = winners?.findIndex(w => w.room === stream.room);
    let allWinners = []
    stream.data.map(winner => {
        allWinners = [...allWinners, ...winner.winners]
        return winner.winners;
    });
    if (foundRoom > -1) {
        winners[foundRoom]['data']['batchWinners'] = allWinners;
    } else {
        winners.push({
            room: stream.room,
            username: stream.username,
            data: {
                batchWinners: allWinners
            }
        });
    }
    return winners;
};
