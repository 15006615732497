import React from 'react';
import Hero from '../Components/Hero';
import HowToPlay from '../Components/HowToPlay';
import Contests from '../Components/Contests';
import Counter from '../Components/Counter';
import { useStateValue } from '../Providers/stateProvider';
import { getStats } from '../utils/poolWinners';


const Home = () => {
    const [{ winners }] = Object.values(useStateValue());
    const stats = getStats(winners);

    return (
        <div className="page-wrapper">
            <Hero />
            <Counter stats={ stats } />
            <HowToPlay />
            <Contests />
        </div>
    );
};

export default Home;
