import React from 'react';

const Footer = () => {
  return (
    <div className="container">
      <hr />
      <div className="row py-5 align-items-center">
        <div className="col-lg-6">
          <p className="copy-right-text text-lg-left text-center mb-lg-0 mb-3">Copyright © 2021. All Rights Reserved By <a href="/">Mitambo</a></p>
        </div>
        {/* <div className="col-lg-6">
          <ul className="social-links justify-content-lg-end justify-content-center">
            <li><a href="#0"><i className="fab fa-facebook-f"></i></a></li>
            <li><a href="#0"><i className="fab fa-twitter"></i></a></li>
            <li><a href="#0"><i className="fab fa-linkedin-in"></i></a></li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
