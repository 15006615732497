import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const LatestWinner = ({ winner }) => {
    return (
      <section className="mt-minus-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="winner-details-wrapper bg_img" data-background="/assets/images/elements/winner-details.jpg" style={{ backgroundImage: "url('/assets/images/elements/winner-details.jpg')"}}>
                { winner ? 
                <>
                  <div className="left"><img src="/assets/images/contest/1.png" alt="s"/></div>
                  <div className="body">
                    <p className="contest-number">Contest No: {winner.username}</p>
                    <p className="contest-date"><span>Draw took place on :</span> {moment(winner.timeOfWinning).format('dddd, MMMM Do YYYY')}</p>
                    <div className="line"></div>
                    <h4 className="title">Latest winner public key:</h4>
                    <ul className="numbers">
                      { winner.publicKey.slice(0, 7).split('').map((l, i) => (
                        <li key={i}>{l}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="right"><img src="/assets/images/contest/7.png" alt="s"/></div>
                </>
                : 
                <>
                  <div className="body" style={{ margin: 'auto'}}>
                    <div className="line"></div>
                    <h4 className="title">We don't have a winner yet, be our first winner!</h4>
                    <h4>{">>"} <Link to='/contest'>Checkout the Areana</Link> {"<<"}</h4>
                  </div>
                </>}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default LatestWinner;
