export const arenas = [
    {
        name: 'Kinshasa',
        poolSize: 5,
        overview: 'Lots of stuff to be said here, okay ingnore now...',
        QRCode: '/assets/images/contest/kinshasa.jpeg',
        deepLink: 'https://bantupayapp.page.link/Ng7K',
        amount: '5',
        total: 23.75,
        username: '5/5',
        usernameUrl: 'g5x5',
        details: 'Winner Takes All'
    },
    {
        name: 'Luanda',
        poolSize: 100,
        overview: 'Lots of stuff to be said here, okay ingnore now...',
        QRCode: '/assets/images/contest/luanda.jpeg',
        deepLink: 'https://bantupayapp.page.link/zfEP',
        amount: '5',
        total: 475,
        username: '5/100',
        usernameUrl: 'g5x100',
        details: '10 lucky winners'
    },
    {
        name: 'Cairo',
        poolSize: 10,
        overview: 'Lots of stuff to be said here, okay ingnore now...',
        QRCode: '/assets/images/contest/cairo.jpeg',
        deepLink: 'https://bantupayapp.page.link/GzuL',
        amount: '100',
        total: 950,
        username: '100/10',
        usernameUrl: 'g100x10',
        details: 'Winner Takes All'
    }
];
