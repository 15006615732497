import React, {  useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';


const Winners = ({ winners }) => {
    const [winnersToShow, setWinnersToShow] = useState(10);
    return (
       <>
        <section className="latest-winner-section position-relative pt-120 pb-120">
          <div className="el-1"><img src="assets/images/elements/w-el-1.png" alt="e" /></div>
          <div className="el-2"><img src="assets/images/elements/w-el-2.png" alt="mage" /></div>
          <div className="el-3"><img src="assets/images/elements/w-el-3.png" alt="mage" /></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
              { winners.length > 0 ? 
                <div className="section-header text-center">
                  <span className="section-sub-title">Meet the latest winners from your favorite contest</span>
                  <h2 className="section-title">Latest Winners</h2>
                </div>
                : null }
              </div>
            </div>

            <div className="row wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
              <div className="col-lg-12">
                <div className="tab-content mt-50" id="winnerTabContent">
                  <div className="tab-pane fade show active" id="dream" role="tabpanel" aria-labelledby="dream-tab">
                    <div className="row mb-none-30" style={{justifyContent: 'center'}}>
                      { winners.length > 0 ? 
                          <div className="col-lg-8 mb-30">
                            { winners?.slice(0, winnersToShow)?.map((w, i)=> (
                              <div key={i} className="winner-card mb-30">
                                <div className="winner-card__thumb">
                                  {w.amount} XBN
                                </div>
                                <div className="winner-card__content">
                                  <div className="content-top">
                                    <div className="left">
                                      <h5>{w.room} Arena</h5>
                                    </div>
                                    <div className="right">
                                      <span>Draw took place on</span>
                                      <p> {moment(w.timeOfWinning).format('dddd, MMMM Do YYYY')}</p>
                                    </div>
                                  </div>
                                  <div className="content-bottom">
                                    <div className="number-list-wrapper">
                                      <p>Public Key:</p>
                                      <ul className="number-list mt-2">
                                      { w.publicKey.slice(0, 7).split('').map((l, a) => (
                                        <li key={a}>{l}</li>
                                      ))}
                                      </ul>
                                    </div>
                                    <div className="right">
                                      <p>Contest No:</p>
                                      <span className="contest-num">{w.username}</span>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          )) }
                        { winnersToShow < winners?.length ? 
                          <div className="btn-grp">
                            <Link to="#" deactivated  onClick={ () => setWinnersToShow(winnersToShow + 10) } className="btn-border">browse more</Link>
                          </div>
                        : 
                        null 
                        }
                      </div>
                      : null
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       </>
    )
}

export default Winners;
