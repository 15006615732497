import React from 'react';
import { Link } from 'react-router-dom';
import Contests from '../Components/Contests';

const AllContests = () => {
  return (
    <div className="page-wrapper">
      <div className="inner-hero-section pt-100 style--three">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul className="page-list">
                <li><Link to="/">Home</Link></li>
                <li className="active">Contest</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- inner-hero-section end --> */ }
      <section className="pb-50 mt-minus-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            </div>
          </div>
        </div>
      </section>

      <Contests />
      <section className="pb-120">
        <div className="container">
          <div className="row mb-none-30 justify-content-center">
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="icon-item2">
                <div className="icon-item2__icon">
                  <img src="/assets/images/icon/contest-feature/1.png" alt="contest" />
                </div>
                <div className="icon-item2__content">
                  <h3 className="title">Instant Winnings</h3>
                  <p>It is fast and transparent. Fast to play, fast to win. Some Arenas even have consolation prizes! So what are you waiting for?</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="icon-item2">
                <div className="icon-item2__icon">
                  <img src="/assets/images/icon/contest-feature/2.png" alt="contest" />
                </div>
                <div className="icon-item2__content">
                  <h3 className="title">Play for a Cause</h3>
                  <p>You can choose to play for a charitable cause. Part of the winning bag will be instantly distributed to the wallet of your chosen charity organisation.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30">
              <div className="icon-item2">
                <div className="icon-item2__icon">
                  <img src="/assets/images/icon/contest-feature/3.png" alt="constrs" />
                </div>
                <div className="icon-item2__content">
                  <h3 className="title">Play Anywhere</h3>
                  <p>You can play Mitambo games from anywhere in the world using Bantu Network Tokens. No Geographical Boundaries</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AllContests;
