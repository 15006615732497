import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { decode64 } from '../utils/base64';
import { arenas } from '../utils/rooms';
import { useStateValue } from '../Providers/stateProvider';

import './contests.css'

const ContestDetails = () => {
  const [{ participants: participantState = [], winners: winnerState = [] }] = Object.values(useStateValue());

  const params = useParams();
  const username = decode64(params.id);

  const arenaDetails = arenas.find(a => a.name === username);
  const winnerData = winnerState?.find(w => w.room === username);
  const participantsData = participantState?.find(w => w.room === username);

  const [isCopied, setIsCopied] = useState(false);
  const [participantsToShow, setParticipantsToShow] = useState(10);
  const [winnersToShow, setWinnersToShow] = useState(10);
  const participantLength = 20;
  let winners = [];
  let participants = [];

  if (winnerData) {
    const { data: { batchWinners } } = winnerData;
    winners = batchWinners;
  }

  if (participantsData) {
    const { participants: p } = participantsData;
    participants = p;

  }

  const byDate = (a, b) => {
    return new Date(b.timeOfWinning) - new Date(a.timeOfWinning);
  };

  const kinshasaInfo = (
    <ul style={ { listStyle: "circle" } }>
      <li>Kinshasha Arena</li>
      <li>Amount to send: 5 XBN</li>
      <li>Number of players: 5</li>
      <li>Username to send to: 5/5</li>
      <li>Number of winners: 1</li>
      <li>Prizes (XBN)</li>
      <li>Pool Commission: 1.2500000</li>
      <li>Winner takes all: 23.7500000</li>
    </ul>
  );
  const luandaInfo = (
    <ul style={ { listStyle: "circle" } }>
      <li>Luanda Arena</li>
      <li>Amount to send: 5 XBN</li>
      <li>Number of players: 100</li>
      <li>Username to send to: 5/100</li>
      <li>Number of winners: 10</li>
      <li>Prizes (XBN)</li>
      <li>Pool Commission 25.0000000</li>
      <li>1st Position 237.5000000</li>
      <li>2nd Position 95.0000000</li>
      <li>3rd Position 47.5000000</li>
      <li>4th Position to 10th Position 13.5714286 each</li>
    </ul>
  );
  const cairoInfo = (
    <ul style={ { listStyle: "circle" } }>
      <li>Cairo Arena</li>
      <li>Amount to play: 100XBN</li>
      <li>Number of players: 10</li>
      <li>Username to send to: 100/10</li>
      <li>Number of winners: 1</li>
      <li>Prizes (XBN)</li>
      <li>Pool Commission: 50.0000000</li>
      <li>Winner takes all: 950.0000000</li>
    </ul>
  );

  const infoDetails = () => {
    if (arenaDetails.name === 'Kinshasa') {
      return kinshasaInfo;
    }
    else if (arenaDetails.name === 'Luanda') {
      return luandaInfo;
    }
    else return cairoInfo;
  };
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (

    <>
      <div className="inner-hero-section">
        <div className="bg-shape"><img src="/assets/images/elements/inner-hero-shape.png" alt="hero" /></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ul className="page-list">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/contest">Contests</Link></li>
                <li className="active">Contest No: { arenaDetails.username }</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className="pb-120 mt-minus-300">
        <div className="container">
          <div className="row justify-content-center">
          </div>
          <div className="row mt-50 pt-3">

            <div className="col-lg-12" style={ { background: '#4822ca', padding: '30px', borderRadius: '20px', marginTop: "30px" } }>
              <div className="contest-details-content">
                <div className="row">
                  <div className="col-lg-9">
                    <span className="subtitle">Enter now for a chance to win</span>
                    <div className="row pl-3">
                      <span className="contest-name">{ arenaDetails.name } Arena</span>
                      <span style={ { cursor: 'pointer', paddingLeft: "10px", alignSelf: "center" } }><i data-tip="More Information" data-place="bottom" data-for="info" className="las la-info-circle fa-lg"></i>
                        <ReactTooltip type="dark" id="info" place="top" effect="solid">
                          <span>{ infoDetails() }</span>
                        </ReactTooltip>
                      </span>

                    </div>
                    <span><em style={ { color: '#f3ba35' } }>{ arenaDetails.details }</em></span>
                    <div className='pt-3'>
                      <div><p>Last Draw: { winners?.length > 0 ? new Date(winners[0].timeOfWinning).toLocaleDateString() : '...' }</p></div>
                      <div className="contest-details-meta">
                        <p>Maximum of <span>{ arenaDetails.poolSize }</span> entries.</p>
                      </div>

                      <div className="contest-details-meta">
                        <p>Amount: <span>{ arenaDetails.amount }</span> XBN</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <p className="text-center pt-3">Scan QR Code</p>
                    <img style={ { width: '100%', maxHeight: '318px' } } src={ arenaDetails.QRCode } alt="2" />
                    <br />
                    <a href={arenaDetails.deepLink} target="_blank" rel="noreferrer" className="cmn-btn">Pay with BantuPay</a>
                    <br />
                    <div>
                      <CopyToClipboard text={ arenaDetails.username } onCopy={ onCopyText }>
                        <p style={ { color: "#ffffff", fontWeight: 700, fontSize: "16px" } } className="text-center">BantuPay Username: <span style={ { color: "#ffffff", fontSize: "16px" } }>{ arenaDetails.username }&nbsp;</span>
                          <i style={ { cursor: 'pointer' } } data-tip="copy" data-for="username" className="las la-copy"></i>
                          <ReactTooltip type="dark" id="username" place="top" effect="solid">
                            <span>{ isCopied ? "copied!" : "Copy username" }</span>
                          </ReactTooltip>
                        </p>
                      </CopyToClipboard>

                    </div>

                  </div>
                </div>


                <div className="contest-description">
                  <div className="content-block">
                    <h6 className="title" style={ { color: "#f3ba35" } }>Mitambo is the first 3rd party DApp in the Bantu ecosystem leveraging BantuPay, and the Bantu Blockchain</h6>
                    <div className="row">
                      <div className="col-lg-4">
                        <ReactPlayer url='https://vimeo.com/543915150' width="316px" height="325px" />
                      </div>
                      <div className="col-lg-8">
                        <p className="pb-3" style={ { color: "#ffffff", fontWeight: 700 } }>To play:</p>
                        <ul style={ { listStyle: "circle" } }>
                          <li>Login to your BantuPay wallet and tap on the XBN asset. Tap on the send icon.</li>
                          <li>Tap the recipient address field. This will reveal a QR code icon. Tap this icon to launch your phone’s QR code scanner.</li>
                          <li>Scan the QR code of the Mitambo Arena you wish to play at. Alternatively, you may tap to copy the BantuPay username of the Arena. </li>
                          <li>Enter the correct amount of XBN for that Arena</li>
                          <li>Tap the continue button and proceed to send.</li>
                          <li>Wait to for the Arena to hit its maximum pool limit.</li>
                          <li>A lot is automagically drawn and the winner of that game batch is instantly announced and rewarded from the pool bag.</li>
                          <li>There is no limit to how many times one can play in Mitambo.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="past-draw-wrapper" style={ { borderRadius: '20px' } }>
                <h3 className="title">Participants ({participants.length}/{arenaDetails.poolSize})</h3>
                <div className="table-responsive-lg">
                  <table>
                    <thead>
                      <tr>
                        <th>Public Key</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      { participants?.length > 0 ?
                        participants && participants.slice(0, participantsToShow).map((p, i) => (
                          <tr key={ i }>
                            <td><span style={ { fontSize: '13px', color: 'white' } } >{ p?.publicKey?.substring(0, participantLength) + '...' }</span></td>
                            <td><span >{ arenaDetails.amount } XBN</span></td>
                          </tr>
                        )) :
                        <tr>
                          <td colSpan="2" style={ { color: 'white', textAlign: 'center' } }>No Participants yet</td>
                        </tr>
                      }

                    </tbody>
                  </table>
                </div>
                <div className="load-more">
                  { participantLength < participants?.length ?
                    <button type="button" onClick={ () => setParticipantsToShow(participantsToShow + 2) }>Show More <i className="las la-angle-down ml-2"></i></button>
                    : <button type="button" /> }
                </div>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="past-draw-wrapper" style={ { borderRadius: '20px' } }>
                <h3 className="title">Latest Winners</h3>
                <div className="table-responsive-lg">
                  <table>
                    <thead>
                      <tr>
                        <th>Draw</th>
                        <th>Batch ID</th>
                        <th>Public Key</th>
                        <th>Amount</th>
                        <th>Position</th>

                      </tr>
                    </thead>
                    <tbody>
                      { winners?.length > 0 ?
                        winners && winners.sort(byDate).slice(0, winnersToShow).map((winner, i) => (
                          <tr key={ i }>
                            <td><span className="fail" style={ { fontSize: '12px', textTransform: 'capitalize' } }>{ moment(winner.timeOfWinning).fromNow() }</span></td>
                            <td><span className="fail">{ winner.batchID }</span></td>
                            <td><span className="fail">{ winner.publicKey.slice(0, 10) }...</span></td>
                            <td><span className="fail">{ winner.amount } XBN</span></td>
                            <td><span className="fail">{ winner.winnerPosition }</span></td>
                          </tr>
                        )) :
                        <tr>
                          <td colSpan="6" style={ { color: 'white', textAlign: 'center' } }>No Winners yet</td>
                        </tr>
                      }

                    </tbody>
                  </table>
                </div>
                <div className="load-more">
                  { winnersToShow < winners.length ?
                    <button type="button" onClick={ () => setWinnersToShow(winnersToShow + 10) }>Show More <i className="las la-angle-down ml-2"></i></button>
                    : <button type="button" /> }
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default ContestDetails;
