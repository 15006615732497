import Types from './participantsType';

const participantsReducer = (participants = [], action) => {
    switch (action.type) {
        case Types.LOAD_PARTICIPANT_STREAM:
            const foundRoom = participants.findIndex(p => p.room === action.payload.room);
            if (foundRoom > -1) {
                participants[foundRoom] = action.payload;
            } else {
                participants.push({
                    room: action.payload.room,
                    username: action.payload.username,
                    participants: action.payload.participants
                })
            }
            return participants
        case Types.NEW_PARTICIPANT:
            const fRoom = participants.findIndex(p => p.room === action.payload.room);
            if (fRoom > -1) {
                participants[fRoom]['participants'] = [action.payload.participant].concat(participants[fRoom]['participants']);
            } else {
                participants.push({
                    room: action.payload.room,
                    username: action.payload.username,
                    participants: [action.payload.participant]
                })
            }

            return participants
        default:
            return participants;
    }
}

export default participantsReducer;
