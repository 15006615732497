const byDate = (a, b) => {
    return new Date(b.timeOfWinning) - new Date(a.timeOfWinning);
}

const injectRoomName = (list) => {
    const room = list.room;
    const username = list.username;
    const winnerList = list.data.batchWinners;

    let processed = [];
    winnerList.map(w => {
        w['room'] = room;
        w['username'] = username;
        processed.push(w)
        return true;
    })
    return processed;
}

export const poolwinners = (winnerState) => {
    let allWinners = []
    winnerState.map(winner => {
        const winnerData = injectRoomName(winner)
        allWinners = [...allWinners, ...winnerData];
        return allWinners.sort(byDate);
    })
    return allWinners;
}

export const getStats = (winnerState) => {
    let totalWinners = 0;
    let totalGameWinners = 0;
    let ticketsSold = 0;
    let payouts = 0;

    winnerState.map(w => {
        const data = w.data;
        totalGameWinners += data.totalGameWinners ? data.totalGameWinners : 0;
        payouts += data.totalGameWinnings ? data.totalGameWinnings : 0;
        ticketsSold += data.totalGameValidPlayers ? data.totalGameValidPlayers : 0;
        return true;
    })
    return {
        totalWinners,
        ticketsSold,
        payouts,
        totalGameWinners,
    }
}