import winnerState from './winnersState';
import Types from './winnersType';
import { getWinnersFromBatch } from './winnerUtils';

const participantsReducer = (winners = winnerState, action) => {
    switch (action.type) {
        case Types.NEW_WINNERS_STREAM:
            const foundRoom = winners?.findIndex(w => w.room === action.payload.room);
            const win = action.payload.data;
            if (foundRoom > -1) {
                winners[foundRoom]['data']['batchWinners'] = action.payload.data.batchWinners.winners.concat(winners[foundRoom]['data']['batchWinners']);
            } else {
                winners.push({
                    room: action.payload.room,
                    username: action.payload.username,
                    data: {
                        batchWinners: action.payload.data.batchWinners.winners.reverse()
                    },
                });
            }
            return winners
        case Types.LOAD_WINNERS_STREAM:
            const winnershistory = getWinnersFromBatch(action.payload, winners);
            return winnershistory;
        case Types.LOAD_STATS:
            const fRoom = winners?.findIndex(w => w.room === action.payload.room);
            const wi = action.payload.data;
            if (fRoom > -1) {
                winners[fRoom] = {
                    room: action.payload.room,
                    username: action.payload.username,
                    data: {
                        batchWinners: winners[fRoom].data.batchWinners,
                        totalGameAmount: wi.totalGameAmount,
                        totalGameValidPlayers: wi.totalGameValidPlayers,
                        totalGameWinners: wi.totalGameWinners,
                        totalGameWinnings: wi.totalGameWinnings,
                    }
                }
            } else {
                winners.push({
                    room: action.payload.room,
                    username: action.payload.username,
                    data: {
                        // batchWinners: win.batchWinners.winners.reverse(),
                        batchWinners: winners[fRoom].data.batchWinners,
                        totalGameAmount: win.totalGameAmount,
                        totalGameValidPlayers: win.totalGameValidPlayers,
                        totalGameWinners: win.totalGameWinners,
                        totalGameWinnings: win.totalGameWinnings,
                    }
                });
            }
            return winners;
        default:
            return winners;
    }
}

export default participantsReducer;
